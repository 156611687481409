import { get, post } from '@/utils/request'

const mapAPI = {
  center: '/api/geo/map/center',
  markers: '/api/geo/map/markers',
  markerInfoApi: '/api/geo/map/marker',
  location: '/api/geo/map/location',
  mainland: '/api/geo/map/cn',
  geoItemApi: '/api/geo/map/item',
  addGeoPointApi: '/api/geo/map/point'
}

export function getMapCenter() {
  return get(mapAPI.center)
}

export function getMarkerInfo(id) {
  return get(mapAPI.markerInfoApi + '?id=' + id)
}

export function getMapMarkers(type) {
  return get(mapAPI.markers + '?type=' + type)
}

export function sendClickedLocation(loc) {
  return post(mapAPI.location, loc)
}

export function getMainLand() {
  return get(mapAPI.mainland)
}

export function getGeoItems() {
  return get(mapAPI.geoItemApi)
}

export function addGeoPoint(data) {
  return post(mapAPI.addGeoPointApi, data)
}

export function getGeoPoint() {
  return get(mapAPI.addGeoPointApi)
}
