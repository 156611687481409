<template>
  <el-row class="movie-list">
    <el-col :md="24">
      <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-select
              v-model="province"
              size="small"
              style="width: 170px"
              placeholder="请选择省份（直辖市）"
              @change="changeProvince()"
            >
              <el-option
                v-for="item in provinces"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              />
            </el-select> 省
            <el-select
              v-model="city"
              size="small"
              style="width: 160px;margin-left: 10px"
              placeholder="请选择城市"
              @change="changeCity"
            >
              <el-option
                v-for="item in citys"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              />
            </el-select> 市
            <el-select
              v-model="district"
              size="small"
              style="width: 160px;margin-left: 10px"
              placeholder="请选择区（县）"
              @change="changeDistrict"
            >
              <el-option
                v-for="item in districts"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              />
            </el-select> 区
          </div>
          <div id="container" class="text item amap-wrapper" />
        </el-card>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { addGeoPoint, getGeoPoint } from '@/api/map'

export default {
  name: 'TrailMap',
  data() {
    return {
      amap: null,
      plugins: ['Scale'],
      mapCenter: [114.0000, 30.0000],
      // zoom=6 的比例尺为 100km
      zoom: 6,
      province: '',
      provinces: [],
      city: '',
      citys: [],
      district: '',
      districts: [],
      path1: [{
        path: [
          [116.361904, 39.913423],
          [116.367904, 39.913423]
        ]
      }],
      path: []
    }
  },
  mounted() {
    this.initAMap()
  },
  unmounted() {
    this.map?.destroy()
  },
  created() {
    document.title = '地图'
  },
  methods: {
    initAMap() {
      window._AMapSecurityConfig = {
        securityJsCode: ''
      }
      AMapLoader.load({
        key: '', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar',
          'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.MassMarks', 'AMap.Size',
          'AMap.Pixel'], // 需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
        AMapUI: { // 重点就是这个
          version: '1.0',
          plugins: ['misc/PathSimplifier', 'overlay/SimpleMarker']// SimpleMarker设置自定义图标，PathSimplifier轨迹展示组件
        }
      }).then((AMap) => {
        // 设置地图容器id
        this.map = new AMap.Map('container', {
          viewMode: '2D', // 是否为3D地图模式
          zoom: 6, // 初始化地图级别
          center: [114.0000, 30.0000] // 初始化地图中心点位置
        })

        this.map.on('click', (e) => {
          const geoPoint = {}
          geoPoint.lng = e.lnglat.getLng()
          geoPoint.lat = e.lnglat.getLat()
          addGeoPoint(geoPoint).then(resp => {
            if (resp.code === 0) {
              // 获取经纬度
              this.$message.info('map click: ' + e.lnglat.getLng() + ' ' + e.lnglat.getLat())
            }
          })
        })
        // this.loadPathSimplifier()
        // this.getDistrict(this.map)
      }).catch((e) => {
        console.log(e)
      })
    },
    zoomchange(e) {
      console.log('当前缩放级别: ' + this.amap.getZoom())
    },
    // 轨迹巡航
    loadPathSimplifier() {
      AMapUI.load(['ui/misc/PathSimplifier'], (PathSimplifier) => {
        if (!PathSimplifier.supportCanvas) {
          alert('当前环境不支持 Canvas！')
          return
        }
        // 创建组件实例
        var pathSimplifierIns = new PathSimplifier({
          map: this.map,
          zIndex: 100, // 图层叠加顺序
          data: this.path, // 巡航路径
          // 获取巡航路径中的路径坐标数组
          getPath: (pathData, pathIndex) => {
            return pathData.path
          }
        })
        // 创建巡航器
        var pathNavigator = pathSimplifierIns.createPathNavigator(0, {
          loop: false, // 是否循环
          speed: 2000 // 速度(km/h)
        })
        pathNavigator.start()
      })
    },
    getDistrict(map) {
      // 创建行政区查询对象
      var district = new AMap.DistrictSearch({
        // 返回行政区边界坐标等具体信息
        extensions: 'all',
        // 设置查询行政区级别为 区
        level: 'street',
        subdistrict: 3
      })

      this.zoom = 11
      const area = '双流'
      district.search(area, function(status, result) {
        var bounds = result.districtList[0].boundaries
        var polygons = []
        if (bounds) {
          for (var i = 0, l = bounds.length; i < l; i++) {
            // 生成行政区划polygon
            var polygon = new AMap.Polygon({
              map: map,
              strokeWeight: 1,
              path: bounds[i],
              fillOpacity: 0.7,
              fillColor: '#CCF3FF',
              strokeColor: '#CC66CC'
            })
            polygons.push(polygon)
          }

          // 地图自适应
          map.setFitView()
        }
      })
    },
    changeProvince() {
    },
    changeCity() {
    },
    changeDistrict() {
    }
  }
}
</script>

<style>
.amap-wrapper {
  width: 100%;
  height: 600px;
}

.movie-list {
  padding-top: 15px;
  padding-left: 3%;
  padding-right: 3%;
}
</style>
